<template>
  <div>
    <a-modal
      :visible="visible"
      title="半成品入库"
      :width="800"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      okText="入库"
      @cancel="cancel"
      @ok="confirm"
    >
      <a-row style="max-height: 400px; overflow: auto">
        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="dataItems"
            :pagination="false"
            :loading="queryLoading"
          >
            <template slot="stock_in_quantity" slot-scope="value, item">
              <a-input-number v-model="item.stock_in_quantity" size="small" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { productionOrderStockIn } from "@/api/production";
import { warehousesOption } from "@/api/option";

export default {
  props: ["visible", "items"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      queryLoading: false,
      confirmLoading: false,
      dataItems: [],
      warehouseItems: [],

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "生产工单号",
          dataIndex: "production_order_number",
          width: 200,
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "已成品入库",
          dataIndex: "completed_quantity",
        },
        {
          title: "入库数量",
          dataIndex: "stock_in_quantity",
          scopedSlots: { customRender: "stock_in_quantity" },
        },
      ],
    };
  },
  computed: {},
  methods: {
    async confirm() {
      this.confirmLoading = true;
      for (const item of this.dataItems.filter(_item => _item.stock_in_quantity > 0)) {
        await productionOrderStockIn(item);
      }
      this.$message.success("报工完成");
      this.confirmLoading = false;
      this.$emit("create");
      this.cancel();
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
  watch: {
    visible(value) {
      if (value) {
        warehousesOption({ page_size: 999999, is_active: true }).then((data) => {
          this.warehouseItems = data.results;
        });

        this.queryLoading = true;
        const dataItems = [];
        for (const item of this.items) {
          const totalStockInQuantity = item.production_stock_in_goods_items.reduce((acc, item) => {
              const quantity = item.stock_in_quantity || 0;
              return acc + quantity;
            }, 0);

          dataItems.push({
            id: item.id,
            production_order_number: item.number,
            goods_name: item.goods_name,
            goods_number: item.goods_number,
            completed_quantity: totalStockInQuantity,
            stock_in_quantity: item.quantity_produced,
          });
        }
        this.dataItems = dataItems;
        this.queryLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
